import React from "react";
import { useLayoutEffect } from "react";
import $ from "jquery";
import "./css/custom.scss";
import "./css/tariffmatch.css";
import "./App.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
  useLocation,
} from "react-router-dom";

import Home from "./components/home";
import Clients from "./components/clients";
import About from "./components/about";
import Contact from "./components/contact";
import Privacy from "./components/privacy";
import Terms from "./components/terms";
import FourOhFour from "./components/404";

import CookieConsent from "react-cookie-consent";

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

class App extends React.Component {
  state = {
    collapseNav: false,
  };

  onScroll = (e) => {
    var $scrollTop = e.target.scrollingElement.scrollTop;
    var collapseNav = false;

    var $navbar = $("#navbar");

    if ($scrollTop > $navbar.height() * 0.6) {
      collapseNav = true;
    } else {
      collapseNav = false;
    }

    this.setState({ collapseNav });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
  }

  render() {
    return (
      <React.Fragment>
        <Router>
          <ScrollToTop>
            <div className="App">
              <header>
                <nav
                  id="navbar"
                  className={
                    this.state.collapseNav === false
                      ? "navbar navbar-expand-lg p-3 navbar-dark fixed-top unscrolled"
                      : "navbar navbar-expand-lg p-3 navbar-light fixed-top scrolled"
                  }
                >
                  <a href="https://www.tariffmatch.com/">
                    <span className="d-none">Tariffmatch</span>
                    {this.state.collapseNav === false ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="header-logo"
                        viewBox="0 0 163.88523 26.05507"
                      >
                        <path
                          d="M798.52765,9.07465c1.2478.50831.95373,1.47925-.51245,2.1691a7.77014,7.77014,0,0,1-5.27948.24029c-1.52686-.54366-1.55628-1.47159.18-2.13338A9.36114,9.36114,0,0,1,798.52765,9.07465Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M808.40918,7.48816c.734.46969.10608,1.0175-1.38379,1.2182a9.4539,9.4539,0,0,1-4.52685-.55351c-1.03113-.49033-.5141-1.02986,1.26409-1.21724A9.33786,9.33786,0,0,1,808.40918,7.48816Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M826.53412,10.137c.40607.416.24335.57509-.423.30383a12.01319,12.01319,0,0,1-2.21014-1.24624c-.53284-.40158-.394-.5321.41577-.26927A6.50532,6.50532,0,0,1,826.53412,10.137Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M828.67432,11.1014c.36786.48305.53515.85139.26989.69924a6.34938,6.34938,0,0,1-1.19549-1.1525c-.35382-.43668-.34107-.62593-.07324-.51414A3.20525,3.20525,0,0,1,828.67432,11.1014Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M815.97754,7.59066c.63538.45188.295.79566-.86713.7567a7.04071,7.04071,0,0,1-3.28216-.9381c-.6153-.46047-.17353-.83064.95971-.7904A6.93574,6.93574,0,0,1,815.97754,7.59066Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M821.815,8.58852c.6175.40522.35376.55851-.60467.36291A11.06971,11.06971,0,0,1,818.19751,7.842c-.70386-.43394-.66791-.70921.39349-.47889A12.33353,12.33353,0,0,1,821.815,8.58852Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M805.5899,13.90313a1.98366,1.98366,0,0,1-.24877,3.29949,3.763,3.763,0,0,1-4.3863-.51858c-1.24432-1.12364-1.28064-2.68945.0578-3.42561A4.27822,4.27822,0,0,1,805.5899,13.90313Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M813.15137,11.70462A1.25352,1.25352,0,0,1,812.542,13.951a4.5026,4.5026,0,0,1-3.92254-1.21294c-1.03162-1.01041-.84461-2.037.4992-2.27877A4.951,4.951,0,0,1,813.15137,11.70462Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#f28e22"
                        />
                        <path
                          d="M828.4563,13.09121c.17853.56938-.09284.86238-.61353.62683a3.11515,3.11515,0,0,1-1.35492-1.53048c-.22943-.59966.06318-.83352.63532-.5678A2.74413,2.74413,0,0,1,828.4563,13.09121Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M830.38708,14.32626c.20978.59158.1695.97455-.11993.777a3.2691,3.2691,0,0,1-.853-1.44326c-.17346-.56668-.07129-.80672.18238-.63413A2.99309,2.99309,0,0,1,830.38708,14.32626Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M819.8067,11.23831c.5362.76881.16657,1.49194-.81286,1.58167a4.11169,4.11169,0,0,1-3.07117-1.42537c-.72644-.86351-.38666-1.52493.78332-1.52054A4.17144,4.17144,0,0,1,819.8067,11.23831Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M824.907,11.69133c.28955.63451-.08453,1.06031-.82043.97426a3.17835,3.17835,0,0,1-2.07746-1.41652c-.42334-.709-.06872-1.15941.80152-.999A3.12229,3.12229,0,0,1,824.907,11.69133Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M811.38544,20.99591a2.97749,2.97749,0,0,1-.43262,3.81616,2.3031,2.3031,0,0,1-3.4964-.62406,3.03214,3.03214,0,0,1,.31744-4.07785A2.56329,2.56329,0,0,1,811.38544,20.99591Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M817.28778,17.78785a2.103,2.103,0,0,1-.61761,2.81206,2.19642,2.19642,0,0,1-2.85962-1.1351c-.715-1.197-.483-2.57287.56433-2.97234A2.47229,2.47229,0,0,1,817.28778,17.78785Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M829.07178,16.83294c.03808.67216-.3097,1.07845-.76868.93074a1.86861,1.86861,0,0,1-.95789-1.564c-.0736-.72957.27253-1.16436.76764-.95884A1.93262,1.93262,0,0,1,829.07178,16.83294Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M831.19073,18.06316c.06116.65326-.16809,1.02851-.5061.82144a2.17379,2.17379,0,0,1-.66992-1.56092c-.03473-.65064.2005-.97919.51263-.77433A2.25754,2.25754,0,0,1,831.19073,18.06316Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M822.194,16.2793a1.59483,1.59483,0,0,1-.762,2.05847,1.853,1.853,0,0,1-2.08234-1.28934c-.43976-.99859-.0946-1.95967.77045-2.11753A1.92559,1.92559,0,0,1,822.194,16.2793Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M826.05865,15.95111c.11567.7758-.26208,1.384-.83349,1.42161-.57147.03766-1.1955-.56353-1.404-1.413s.16-1.56138.82489-1.52493A1.74553,1.74553,0,0,1,826.05865,15.95111Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M815.34106,28.27231a3.519,3.519,0,0,1-.69476,3.64532c-.89209.99027-2.05627,1.05446-2.64471-.10846a3.83673,3.83673,0,0,1,.63012-4.03054A1.58916,1.58916,0,0,1,815.34106,28.27231Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M819.9505,24.11193a2.83813,2.83813,0,0,1-.59546,2.8825c-.69116.66435-1.61139.487-2.06958-.5949a2.86977,2.86977,0,0,1,.58606-3.17249C818.66315,22.63968,819.59283,23.10707,819.9505,24.11193Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M828.98816,20.95865c-.05957.69552-.41675,1.16348-.78253,1.11423s-.663-.63823-.66461-1.392.34973-1.32729.77771-1.21934C828.74677,19.56954,829.04773,20.26314,828.98816,20.95865Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M831.13306,22.02576c-.079.66722-.46674,1.0087-.81641.80962a1.62352,1.62352,0,0,1-.45673-1.53148c.08167-.67106.42078-1.07006.7688-.86664A1.76447,1.76447,0,0,1,831.13306,22.02576Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M823.57495,21.684a2.20977,2.20977,0,0,1-.58026,2.20839c-.53674.38292-1.22119.03754-1.51282-.91715a2.12942,2.12942,0,0,1,.626-2.40333C822.73816,20.25335,823.37683,20.7937,823.57495,21.684Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M826.52386,20.7042c.05335.797-.23492,1.48628-.66284,1.63066s-.91729-.355-1.05938-1.214c-.14216-.859.183-1.702.7008-1.78388S826.47052,19.9072,826.52386,20.7042Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M850.95917,18.17639a3.25837,3.25837,0,0,0-1.30042.5846,1.32145,1.32145,0,0,0-.49682,1.09149,1.33908,1.33908,0,0,0,.57629,1.18152,3.12625,3.12625,0,0,0,1.75781.39679,4.93734,4.93734,0,0,0,1.79724-.31452,8.55423,8.55423,0,0,0,1.50946-.757v-2.542q-.85363.04011-2.01129.12232A12.50764,12.50764,0,0,0,850.95917,18.17639Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="none"
                        />
                        <path
                          d="M916.32489,18.17639a3.25749,3.25749,0,0,0-1.30041.5846,1.3215,1.3215,0,0,0-.49689,1.09149,1.34,1.34,0,0,0,.5758,1.18152,3.1293,3.1293,0,0,0,1.75837.39679,4.93737,4.93737,0,0,0,1.79724-.31452,8.54746,8.54746,0,0,0,1.5094-.757v-2.542q-.85365.04011-2.01069.12232A12.50647,12.50647,0,0,0,916.32489,18.17639Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="none"
                        />
                        <polygon
                          points="45.19 4.843 45.19 4.843 45.2 4.843 45.19 4.843"
                          fill="#fff"
                        />
                        <polygon
                          points="44.315 6.158 48.092 6.158 48.217 6.158 48.217 6.283 48.217 15.858 49.949 15.858 49.949 6.283 49.949 6.158 50.074 6.158 53.921 6.158 53.918 4.968 44.315 4.896 44.315 6.158"
                          fill="#fff"
                        />
                        <polygon
                          points="48.092 15.921 48.092 15.921 48.092 15.902 48.092 15.921"
                          fill="#fff"
                        />
                        <polygon
                          points="65.02 15.983 65.02 15.983 65.016 15.983 65.02 15.983"
                          fill="#fff"
                        />
                        <path
                          d="M854.92773,22.474h1.63062V16.44244a2.88956,2.88956,0,0,0-.31384-1.40991,2.48154,2.48154,0,0,0-.91346-.92023,4.77255,4.77255,0,0,0-1.99963-.5249,10.5614,10.5614,0,0,0-1.39618-.07355c-.84515,0-1.69317.05372-1.99481.0749v1.0343a16.72732,16.72732,0,0,1,1.67438-.08728q.15133-.00285.29-.00281a4.73674,4.73674,0,0,1,2.08331.31018,1.64039,1.64039,0,0,1,.69446.58246,1.669,1.669,0,0,1,.24511.93237v.35687l-.12.00476c-1.07379.04279-2.1004.10846-3.05139.19526a9.79829,9.79829,0,0,0-2.40821.48773,3.48068,3.48068,0,0,0-1.496.9671,2.556,2.556,0,0,0,.485,3.56256,3.92524,3.92524,0,0,0,2.472.78626,7.04352,7.04352,0,0,0,1.39972-.123,6.39581,6.39581,0,0,0,1.05481-.30719,4.94421,4.94421,0,0,0,.80335-.38513q.42489-.2493.67486-.38819l.18585-.10351V22.474Zm-.058-2.00916a8.72481,8.72481,0,0,1-1.53143.768,5.08366,5.08366,0,0,1-1.84229.32294,3.25654,3.25654,0,0,1-1.82873-.41883,1.4619,1.4619,0,0,1-.63037-1.28448,1.44546,1.44546,0,0,1,.54382-1.18915,3.39363,3.39363,0,0,1,1.34991-.60864,12.686,12.686,0,0,1,1.852-.23975c.7948-.05609,1.45368-.09619,2.01423-.12244l.13085-.00616V20.428Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <polygon
                          points="128.567 15.921 128.567 15.921 128.567 15.915 128.567 15.921"
                          fill="#fff"
                        />
                        <polygon
                          points="130.385 15.983 130.385 15.983 130.381 15.983 130.385 15.983"
                          fill="#fff"
                        />
                        <path
                          d="M920.2934,22.474H921.924V16.44244a2.89449,2.89449,0,0,0-.31335-1.41,2.48665,2.48665,0,0,0-.91388-.92017,4.769,4.769,0,0,0-1.99964-.5249,10.5623,10.5623,0,0,0-1.39624-.07355c-.84509,0-1.693.05372-1.99469.0749v1.0343a16.72688,16.72688,0,0,1,1.67432-.08728q.15133-.00285.28992-.00281a4.74436,4.74436,0,0,1,2.08386.31018,1.64137,1.64137,0,0,1,.694.58246,1.66847,1.66847,0,0,1,.24512.93237v.35687l-.12006.00476c-1.07343.04273-2.1.10846-3.05127.19526a9.77626,9.77626,0,0,0-2.40765.48773,3.48013,3.48013,0,0,0-1.49652.9671,2.3789,2.3789,0,0,0-.51258,1.58948,2.40466,2.40466,0,0,0,.99756,1.97308,3.92525,3.92525,0,0,0,2.472.78626,7.04341,7.04341,0,0,0,1.39971-.123,6.39767,6.39767,0,0,0,1.05488-.30719,4.87764,4.87764,0,0,0,.80328-.38513q.42489-.2493.6748-.38819l.18586-.10351V22.474Zm-.05793-2.00916a8.71856,8.71856,0,0,1-1.53143.768,5.0836,5.0836,0,0,1-1.84228.32294,3.26041,3.26041,0,0,1-1.82923-.41877,1.46283,1.46283,0,0,1-.62994-1.28454,1.44541,1.44541,0,0,1,.54388-1.18915,3.39373,3.39373,0,0,1,1.34992-.60864,12.69018,12.69018,0,0,1,1.85254-.23975c.79541-.05615,1.4541-.09625,2.01361-.12244l.13086-.00616V20.428Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <path
                          d="M865.85168,13.67645a5.07993,5.07993,0,0,0-1.70751.2973,9.62806,9.62806,0,0,0-1.95459,1.01856l-.19306.12537V13.67645h-1.63006V22.474h1.63006V16.09839l.05621-.037a8.36215,8.36215,0,0,1,1.58759-.82031,4.80768,4.80768,0,0,1,1.7301-.31964c.37122,0,.68164.01257.949.03839.24835.024.50433.06219.78106.11664V13.75934c-.23846-.03021-.4212-.05188-.54821-.06336C866.40906,13.683,866.17328,13.67645,865.85168,13.67645Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <polygon
                          points="78.767 4.823 78.767 4.823 78.769 4.823 78.767 4.823"
                          fill="#fff"
                        />
                        <rect
                          x="78.82981"
                          y="7.06059"
                          width="1.62946"
                          height="8.79755"
                          fill="#fff"
                        />
                        <rect
                          x="78.8292"
                          y="4.94767"
                          width="1.62622"
                          height="1.32611"
                          fill="#fff"
                        />
                        <polygon
                          points="78.768 6.935 78.768 6.936 78.773 6.936 78.768 6.935"
                          fill="#fff"
                        />
                        <polygon
                          points="99.467 15.921 99.467 15.921 99.467 15.903 99.467 15.921"
                          fill="#fff"
                        />
                        <polygon
                          points="99.529 6.935 99.529 6.936 99.535 6.936 99.529 6.935"
                          fill="#fff"
                        />
                        <polygon
                          points="110.503 15.983 110.503 15.983 110.5 15.983 110.503 15.983"
                          fill="#fff"
                        />
                        <polygon
                          points="103.347 6.998 103.347 6.998 103.347 7.002 103.347 6.998"
                          fill="#fff"
                        />
                        <path
                          d="M905.79224,13.432a5.72225,5.72225,0,0,0-1.96949.33587,9.62679,9.62679,0,0,0-2.0556,1.10193l-.11194.07593-.06689-.11749a2.54626,2.54626,0,0,0-1.13324-1.0221,4.3,4.3,0,0,0-1.882-.37414,5.15489,5.15489,0,0,0-1.82379.322,8.5988,8.5988,0,0,0-1.73187.90625l-.19446.12988V13.67645h-1.62952V22.474H894.823V15.77155l.05914-.03663a10.02023,10.02023,0,0,1,1.56677-.79577,4.31379,4.31379,0,0,1,1.59461-.31671,3.52183,3.52183,0,0,1,1.28906.18365,1.51418,1.51418,0,0,1,.71808.56934,2.15222,2.15222,0,0,1,.29541.91644A9.6696,9.6696,0,0,1,900.412,17.445v5.029h1.63006V16.80475q0-.23676-.01953-.4801-.0195-.24106-.04889-.42359l-.01324-.082.07049-.04394a10.43447,10.43447,0,0,1,1.60212-.82343,4.33522,4.33522,0,0,1,1.62957-.32922,3.4629,3.4629,0,0,1,1.28986.18731,1.543,1.543,0,0,1,.71728.57349,2.16729,2.16729,0,0,1,.29554.921,9.64558,9.64558,0,0,1,.06646,1.14081v5.029h1.62952V16.73035a3.36436,3.36436,0,0,0-.84064-2.43384A3.54072,3.54072,0,0,0,905.79224,13.432Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <polygon
                          points="103.284 15.983 103.284 15.983 103.281 15.983 103.284 15.983"
                          fill="#fff"
                        />
                        <polygon
                          points="139.954 8.213 139.954 8.213 139.95 8.213 139.954 8.213"
                          fill="#fff"
                        />
                        <polygon
                          points="140.017 6.998 140.017 6.998 140.017 6.999 140.017 6.998"
                          fill="#fff"
                        />
                        <path
                          d="M928.58105,21.42413a1.40887,1.40887,0,0,1-.67053-.5205,1.70285,1.70285,0,0,1-.30481-.80976c-.027-.27136-.04065-.65179-.04065-1.13055V14.70355h3.928v-1.0271h-3.928V11.71472h-1.63061v7.93439a2.83838,2.83838,0,0,0,.853,2.298,3.90794,3.90794,0,0,0,2.54212.717,9.763,9.763,0,0,0,1.10925-.07251c.37219-.04236.72626-.09723,1.05432-.16327V21.29846c-.149.04572-.36743.1004-.678.16986a4.6423,4.6423,0,0,1-.99877.11414A3.60614,3.60614,0,0,1,928.58105,21.42413Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <polygon
                          points="140.017 14.606 140.017 14.606 140.017 14.608 140.017 14.606"
                          fill="#fff"
                        />
                        <polygon
                          points="136.089 5.036 136.089 5.036 136.089 5.048 136.089 5.036"
                          fill="#fff"
                        />
                        <polygon
                          points="151.356 9.137 151.356 9.136 151.355 9.136 151.356 9.137"
                          fill="#fff"
                        />
                        <path
                          d="M941.189,14.80719a7.87828,7.87828,0,0,1,1.70593.78265V14.14465a11.27765,11.27765,0,0,0-1.59332-.49713A8.0558,8.0558,0,0,0,939.451,13.432a5.84191,5.84191,0,0,0-3.88184,1.23651,4.22093,4.22093,0,0,0-1.45617,3.39563,4.98763,4.98763,0,0,0,.38708,2.0365,3.7971,3.7971,0,0,0,1.09119,1.4306,4.67582,4.67582,0,0,0,1.68005.84979,7.93413,7.93413,0,0,0,2.17969.28308,7.52442,7.52442,0,0,0,1.76013-.19885,13.75223,13.75223,0,0,0,1.68384-.531V20.50751c-.12659.0777-.28644.16717-.48486.27148-.2345.12433-.489.24274-.77741.3617a5.97579,5.97579,0,0,1-1.08.31933,5.825,5.825,0,0,1-1.1795.12244,3.61773,3.61773,0,0,1-2.62366-.91351,3.48558,3.48558,0,0,1-.93915-2.60486,3.485,3.485,0,0,1,.96051-2.64612,3.65334,3.65334,0,0,1,2.6023-.90448A6.06475,6.06475,0,0,1,941.189,14.80719Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <polygon
                          points="156.461 15.983 156.461 15.983 156.458 15.983 156.461 15.983"
                          fill="#fff"
                        />
                        <path
                          d="M951.36023,14.62244a3.92153,3.92153,0,0,1,1.26721.1726,1.6134,1.6134,0,0,1,.78278.54938,1.99017,1.99017,0,0,1,.35412.90991,7.36777,7.36777,0,0,1,.09278,1.19068v5.029h1.62951V16.73035a3.18488,3.18488,0,0,0-.91357-2.44916,3.838,3.838,0,0,0-2.68494-.84924,5.70339,5.70339,0,0,0-1.89587.32525,8.033,8.033,0,0,0-1.798.90741l-.19452.13013V11.54907H946.369V22.474h1.63067V15.77148l.05927-.03662a8.99778,8.99778,0,0,1,1.64-.797A4.85312,4.85312,0,0,1,951.36023,14.62244Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <polygon
                          points="156.524 4.871 156.523 4.871 156.523 4.887 156.524 4.871"
                          fill="#fff"
                        />
                        <path
                          d="M889.97009,10.79584c.30731.04516.56482.09.7663.13348V9.78253c-.25427-.03638-.52673-.06848-.81165-.09576-.335-.03211-.71283-.04834-1.123-.04834a4.12665,4.12665,0,0,0-2.77771.83282,3.005,3.005,0,0,0-.94745,2.3833v2.17572h-2.37677l.51135,1.0271h1.86542V23.8689h1.63061V16.05737h3.32392v-1.0271h-3.38452V12.8468a2.03007,2.03007,0,0,1,.59179-1.66614,2.95363,2.95363,0,0,1,1.833-.46063A6.17456,6.17456,0,0,1,889.97009,10.79584Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                        <polygon
                          points="98.197 4.447 98.197 4.447 98.197 4.447 98.197 4.447"
                          fill="#fff"
                        />
                        <polygon
                          points="90.981 4.447 90.981 4.447 90.981 4.447 90.981 4.447"
                          fill="#fff"
                        />
                        <polygon
                          points="97.555 8.352 97.555 8.352 97.555 8.354 97.555 8.352"
                          fill="#fff"
                        />
                        <polygon
                          points="97.492 9.567 97.492 9.567 97.48 9.567 97.492 9.567"
                          fill="#fff"
                        />
                        <path
                          d="M878.5553,15.03027h-.125V12.8468a2.02867,2.02867,0,0,1,.59179-1.66614A2.95331,2.95331,0,0,1,880.855,10.72a6.17214,6.17214,0,0,1,.8988.07581c.30549.0448.56281.08966.76569.13348V9.78253c-.25421-.03631-.52667-.06848-.81165-.09576-.335-.03211-.71276-.04834-1.12292-.04834a4.12644,4.12644,0,0,0-2.77771.83282,3.00606,3.00606,0,0,0-.947,2.3833v2.17572h-1.27545v1.0271h1.27545V23.8689h1.63013V16.05737h2.5512l-.511-1.0271Z"
                          transform="translate(-791.6014 -6.61586)"
                          fill="#fff"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="header-logo-small"
                        viewBox="0 0 39.5992 26.05506"
                        width="60"
                        height="39"
                      >
                        <path
                          d="M18.19408,9.11513c1.24781.5083.9537,1.47923-.51247,2.16908a7.77021,7.77021,0,0,1-5.27949.24031c-1.52687-.54368-1.55624-1.4716.18011-2.13339A9.36094,9.36094,0,0,1,18.19408,9.11513Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M28.0756,7.52863c.734.4697.10606,1.0175-1.38383,1.2182A9.45364,9.45364,0,0,1,22.165,8.19332c-1.03115-.49034-.51411-1.02986,1.26409-1.21723A9.33779,9.33779,0,0,1,28.0756,7.52863Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M46.20053,10.17747c.40605.416.24332.57509-.42306.30382a12.01107,12.01107,0,0,1-2.21011-1.24623c-.53284-.40158-.394-.53209.41579-.26927A6.50525,6.50525,0,0,1,46.20053,10.17747Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M48.34071,11.14188c.36786.483.53514.85138.26989.69922a6.34985,6.34985,0,0,1-1.19546-1.15249c-.35385-.43669-.34108-.62593-.07323-.51414A3.20558,3.20558,0,0,1,48.34071,11.14188Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M35.644,7.63114c.63537.45188.295.79564-.86716.75668a7.04041,7.04041,0,0,1-3.28215-.93809c-.61529-.46048-.17351-.83064.95968-.79039A6.93583,6.93583,0,0,1,35.644,7.63114Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M41.48142,8.629c.61751.40522.35373.5585-.60468.36291a11.06988,11.06988,0,0,1-3.01281-1.10945c-.70388-.434-.6679-.70922.39349-.47889A12.33367,12.33367,0,0,1,41.48142,8.629Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M25.25631,13.9436a1.98368,1.98368,0,0,1-.24877,3.2995,3.76306,3.76306,0,0,1-4.38631-.51861c-1.2443-1.12363-1.28064-2.68943.05781-3.4256A4.27826,4.27826,0,0,1,25.25631,13.9436Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M32.81779,11.74508a1.25353,1.25353,0,0,1-.6094,2.24638,4.5026,4.5026,0,0,1-3.92253-1.21294c-1.03161-1.01041-.84463-2.037.49922-2.27878A4.951,4.951,0,0,1,32.81779,11.74508Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#f28e22"
                        />
                        <path
                          d="M48.12271,13.13168c.17853.56937-.09283.86238-.61353.62683A3.11506,3.11506,0,0,1,46.15425,12.228c-.22943-.59965.06315-.83351.6353-.56779A2.74415,2.74415,0,0,1,48.12271,13.13168Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M50.05351,14.36672c.20975.59158.16947.97456-.11994.777a3.26905,3.26905,0,0,1-.853-1.44327c-.17344-.56667-.07128-.80671.18238-.63412A2.99346,2.99346,0,0,1,50.05351,14.36672Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M39.47312,11.27877c.53616.76882.16655,1.492-.81287,1.58168a4.11173,4.11173,0,0,1-3.07116-1.42538c-.72643-.8635-.38667-1.52493.78329-1.52053A4.17149,4.17149,0,0,1,39.47312,11.27877Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M44.57337,11.73179c.28957.63452-.0845,1.06032-.82041.97427a3.17831,3.17831,0,0,1-2.07745-1.41652c-.42334-.709-.06871-1.15942.80153-.999A3.12214,3.12214,0,0,1,44.57337,11.73179Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M31.05187,21.03639a2.97753,2.97753,0,0,1-.43265,3.81617,2.30311,2.30311,0,0,1-3.49639-.62409,3.03215,3.03215,0,0,1,.31741-4.07783A2.56336,2.56336,0,0,1,31.05187,21.03639Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M36.95419,17.82832a2.103,2.103,0,0,1-.6176,2.81207A2.19641,2.19641,0,0,1,33.477,19.50528c-.715-1.197-.483-2.57288.56434-2.97234A2.47226,2.47226,0,0,1,36.95419,17.82832Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M48.73816,16.87341c.03809.67216-.30966,1.07846-.76867.93074a1.86862,1.86862,0,0,1-.95786-1.564c-.07364-.72956.27252-1.16436.76765-.95884A1.93255,1.93255,0,0,1,48.73816,16.87341Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M50.85714,18.10363c.06115.65326-.1681,1.02852-.50608.82145a2.17365,2.17365,0,0,1-.67-1.56093c-.03473-.65063.20052-.97919.51265-.77433A2.25754,2.25754,0,0,1,50.85714,18.10363Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M41.86042,16.31977a1.5948,1.5948,0,0,1-.76194,2.05848,1.853,1.853,0,0,1-2.08236-1.28935c-.43974-.99858-.09457-1.95967.77049-2.11753A1.92555,1.92555,0,0,1,41.86042,16.31977Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M45.72509,15.99158c.11563.7758-.26208,1.384-.83353,1.42162s-1.1955-.56355-1.40406-1.413.16-1.56138.82493-1.52493A1.74561,1.74561,0,0,1,45.72509,15.99158Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M35.00748,28.3128a3.51908,3.51908,0,0,1-.69476,3.64529c-.89209.99027-2.05625,1.05446-2.64468-.10846a3.83656,3.83656,0,0,1,.63007-4.03051A1.58918,1.58918,0,0,1,35.00748,28.3128Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M39.6169,24.1524a2.83813,2.83813,0,0,1-.59543,2.88248c-.69116.66436-1.61139.487-2.06961-.59487a2.86987,2.86987,0,0,1,.586-3.17249C38.32957,22.68015,39.25924,23.14755,39.6169,24.1524Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M48.65457,20.99913c-.05957.69552-.41677,1.16346-.78254,1.11421s-.663-.63822-.66463-1.39193.34974-1.32729.77776-1.21934S48.71413,20.30362,48.65457,20.99913Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M50.79947,22.06623c-.079.66723-.46674,1.00869-.81638.80961a1.62338,1.62338,0,0,1-.45676-1.53147c.08166-.67105.42079-1.07005.76883-.86664A1.76454,1.76454,0,0,1,50.79947,22.06623Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M43.24135,21.72444a2.20963,2.20963,0,0,1-.58028,2.20837c-.53671.38292-1.22114.03756-1.51281-.91713a2.12949,2.12949,0,0,1,.626-2.40334C42.40456,20.29382,43.04326,20.83418,43.24135,21.72444Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                        <path
                          d="M46.19028,20.74468c.05333.797-.2349,1.48626-.66284,1.63064s-.91728-.355-1.05941-1.214.183-1.702.70082-1.78388S46.13694,19.94768,46.19028,20.74468Z"
                          transform="translate(-11.26782 -6.65634)"
                          fill="#000"
                        />
                      </svg>
                    )}
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarCollapse"
                    aria-controls="navbarCollapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse navbar-sm"
                    id="navbarCollapse"
                  >
                    <ul className="navbar-nav me-auto mb-2 p-3 text-center">
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/">
                          SERVICES
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          activeclassname="active"
                          to="/clients"
                        >
                          CLIENTS
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          activeclassname="active"
                          to="/about"
                        >
                          ABOUT
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          activeclassname="active"
                          to="/contact"
                        >
                          CONTACT
                        </NavLink>
                      </li>
                    </ul>

                    <div className="d-flex">
                      <ul className="navbar-nav me-auto mb-2 p-3 w-100">
                        <li className="nav-item">
                          <a
                            id="my-account"
                            className={
                              this.state.collapseNav === false
                                ? "btn bg-white w-100"
                                : "btn btn-orange w-100"
                            }
                            href="https://application.tariffmatch.com"
                          >
                            LOGIN
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </header>

              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/clients" element={<Clients />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/terms-and-conditions" element={<Terms />} />
                <Route path="/404" element={<FourOhFour />} />
                <Route path="/default.aspx" to="/" />
                <Route path="*" element={<FourOhFour />} />
              </Routes>
            </div>
          </ScrollToTop>
        </Router>

        <CookieConsent
          location="bottom"
          buttonClasses="btn btn-primary cookie-button"
          declineButtonClasses="btn btn-primary cookie-button"
          buttonWrapperClasses="cookie-button-wrapper col-lg-2 col-md-12 col-sm-12 my-auto mx-auto"
          containerClasses="alert-warning"
          contentClasses="col-lg-10 col-md-12 col-sm-12 mx-5"
          buttonText="I Accept"
          declineButtonText="No Thanks"
          enableDeclineButton
          flipButtons
        >
          <h5 className="display-7">Can we give you some cookies?</h5>
          <p className="cookie-text">
            We will use necessary cookies to enable site functionality such as
            security and accessibility. You can disable these through your
            browser settings, but this could affect how the website functions.
          </p>
          <p className="cookie-text mb-0">
            We'd like to set Google Analytics cookies that help us improve the
            site. These cookies will not collect any information that would
            directly identify anyone. By selecing 'I Accept' you are accepting
            the use of these.
          </p>
        </CookieConsent>
      </React.Fragment>
    );
  }
}

export default App;
