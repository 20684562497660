import React from "react";
import support from "../images/support.jpg";
import clientCMC from "../images/clients/cmc.png";
import clientExcalibur from "../images/clients/excalibur.png";
import clientTela from "../images/clients/tela.png";
import clientOnecom from "../images/clients/onecom.png";
import clientVodafone from "../images/clients/vodafone.png";
import clientTata from "../images/clients/tatacommunications.png";
import clientVMB from "../images/clients/vmb.png";
import clientCommTech from "../images/clients/comm-tech.png";
import clientThreeBusiness from "../images/clients/threebusiness.png";
import clientUnivonix from "../images/clients/univonix.png";
import clientVerizonBusiness from "../images/clients/verizon_business.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRandom,
  faPoundSign,
  faSpaceShuttle,
  faRobot,
  faPlus,
  faFingerprint,
  faBolt,
  faChartPie,
  faChartBar,
  faQuoteLeft,
  faQuoteRight,
  faUsers,
  faCalendarAlt,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { faSalesforce } from "@fortawesome/free-brands-svg-icons";

import Footer from "../components/footer";

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="splash">
          <div className="splashBackground product bg-dark d-flex"></div>

          <div className="cover-container position-relative flex-column text-left text-white">
            <h1 className="display-5">
              Intelligence based analysis and projection systems providing
              maximum
            </h1>
            <p className="display-4 text-tmo">
              Automation. Efficiency. Accuracy.
            </p>
          </div>
        </div>

        <div className="container marketing">
          <div className="row">
            <div className="col-lg-4">
              <FontAwesomeIcon
                className="iconFeature icon-orange"
                icon={faRandom}
              />
              <h2>Fixed Line &amp; Mobile Analysis Services</h2>
              <p>
                Equipping your teams with Tariffmatch reports gives you a
                competitive edge. Our reporting assists new business and
                retention teams by providing clear and impartial analysis that
                can be taken straight to your customer, showing proof of usage
                and savings.
              </p>
            </div>
            <div className="col-lg-4">
              <FontAwesomeIcon
                className="iconFeature icon-blue"
                icon={faSpaceShuttle}
                rotation={270}
              />
              <h2>The Future of Digital Transformation</h2>
              <p>
                With the pending global ISDN switch off, let us perform the
                required analytics to facilitate moving your customers from TDM
                to SIP, Teams, VoIP, or any other cloud based solution. Manage
                revenue erosion and save time by getting it right first time.
              </p>
            </div>
            <div className="col-lg-4">
              <FontAwesomeIcon
                className="iconFeature icon-purple"
                icon={faBolt}
              />
              <h2>Full Bundle, Bolt On &amp; Plan Support</h2>
              <p>
                Our flexible Bundle, Bolt On and Plan systems allow the
                configuration of even the most complex tariff offerings.
                In-built intelligence means our analytics engines can
                automatically select the configurations to match customer
                requirements.
              </p>
            </div>
          </div>
        </div>

        <div className="container marketing">
          <div className="row">
            <div className="col-lg-4">
              <FontAwesomeIcon
                className="iconFeature icon-red"
                icon={faPoundSign}
              />
              <h2>Automated Pricing Services</h2>
              <p>
                Our intelligent Tariff comparison allows you to automatically
                profile the best fit offering for your requirements, and also
                gives complete flexibility to bespoke tariffs if required.
                Integrate through our API and make this a completely automated
                process.
              </p>
            </div>
            <div className="col-lg-4">
              <FontAwesomeIcon
                className="iconFeature icon-green"
                icon={faCalendarAlt}
              />
              <h2>Automatic Analysis Generation</h2>
              <p>
                Never leave a re-sign to the last minute again. Integration
                services can provide automatic triggering of the data collection
                and analysis process so your sales team have everything they
                need for resign, before they even know they need it.
              </p>
            </div>
            <div className="col-lg-4">
              <FontAwesomeIcon
                className="iconFeature icon-orange"
                icon={faUsers}
              />
              <h2>Profile Management</h2>
              <p>
                Our profile reporting is critical for both telecoms provider and
                customer, identifying areas of concern or mismanagement fast.
                This could be premium rate number abuse or unused lines/handsets
                incurring a cost each month.
              </p>
            </div>
          </div>
        </div>

        <section className="jumbotron p-5 mt-5 mb-5">
          <div className="container clients">
            <p className="display-6">Some of our clients</p>
            <div className="row justify-content-md-center">
              <div className="col-lg-2 col-sm-6 col-6 m-auto">
                <img
                  className="img-fluid"
                  src={clientTata}
                  alt="Client Tata Communications"
                ></img>
              </div>
              <div className="col-lg-2 col-sm-6 col-6 m-auto">
                <img
                  className="img-fluid"
                  src={clientVodafone}
                  alt="Client Vodafone"
                ></img>
              </div>
              <div className="col-lg-2 col-sm-6 col-6 m-auto">
                <img
                  className="img-fluid"
                  src={clientVerizonBusiness}
                  alt="Client Verizon Business"
                ></img>
              </div>
              <div className="col-lg-2 col-sm-6 col-6 m-auto">
                <img
                  className="img-fluid"
                  src={clientVMB}
                  alt="Client Virgin Media Business"
                ></img>
              </div>
              <div className="col-lg-2 col-sm-6 col-6 m-auto">
                <img
                  className="img-fluid"
                  src={clientThreeBusiness}
                  alt="Client Three Business"
                ></img>
              </div>
              <span className="lg-break p-3"></span>
              <div className="col-lg-2 col-sm-6 col-6 m-auto">
                <img
                  className="img-fluid"
                  src={clientCommTech}
                  alt="Client Comm-Tech"
                ></img>
              </div>
              <div className="col-lg-2 col-sm-6 col-6 m-auto">
                <img
                  className="img-fluid"
                  src={clientOnecom}
                  alt="Client Onecom"
                ></img>
              </div>
              <div className="col-lg-2 col-sm-6 col-6 m-auto">
                <img
                  className="img-fluid"
                  src={clientTela}
                  alt="Client Tela"
                ></img>
              </div>
              <div className="col-lg-2 col-sm-6 col-6 m-auto">
                <img
                  className="img-fluid"
                  src={clientCMC}
                  alt="Client CMC"
                ></img>
              </div>
              <div className="col-lg-2 col-sm-6 col-6 m-auto">
                <img
                  className="img-fluid"
                  src={clientUnivonix}
                  alt="Client Univonix"
                ></img>
              </div>
            </div>
          </div>
        </section>

        <div className="container marketing mb-5">
          <div className="row featurette">
            <div className="col-md-7">
              <h3 className="featurette-heading">
                Full System Integration.
                <br />{" "}
                <span className="text-muted">
                  Level up your workflow with automation.
                </span>
              </h3>
              <p className="lead">
                A full set of API endpoints means we can integrate with your
                existing systems, in fact, we recommend it. From Microsoft
                Dynamics 365 to Salesforce CPQ, and even in-house systems, our
                customers are reaping the benefits from system automation.
              </p>
              <p className="lead">
                Our in-house development team are ready to discuss the best way
                to reduce your manual user input so your team can benefit from
                getting the best results everytime.
              </p>
              <p className="feature-action">
                <a href="/contact/">
                  Get in touch to discuss your requirements{" "}
                  <FontAwesomeIcon className="" icon={faArrowCircleRight} />
                </a>
              </p>
            </div>
            <div className="col-md-5 mb-auto mt-auto mx-auto text-center">
              <FontAwesomeIcon
                className="featurette-image img-fluid iconHighlight icon-blue"
                icon={faSalesforce}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 129 129"
                className="svg-inline--fa featurette-image img-fluid iconHighlight iconMicrosoft"
              >
                <title>Microsoft logo</title>
                <path fill="#F25022" d="M0 0h61.3v61.3h-61.3z" />
                <path fill="#7FBA00" d="M67.7 0h61.3v61.3h-61.3z" />
                <path fill="#00A4EF" d="M0 67.7h61.3v61.3h-61.3z" />
                <path fill="#FFB900" d="M67.7 67.7h61.3v61.3h-61.3z" />
              </svg>
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row featurette">
            <div className="col-md-7 order-md-2">
              <h3 className="featurette-heading">
                Mortal and Machine.
                <br />{" "}
                <span className="text-muted">
                  Quality control by experienced analysts.
                </span>
              </h3>
              <p className="lead">
                With many years experience in processing CDRs, whether straight
                out of a switch or billing platform, we know that data often
                isn't perfect.
              </p>
              <p className="lead">
                After our automated systems have worked their magic, our
                dedicated analytics team review what's left, use their extensive
                knowledge to highlight any unusual configurations and anomalies,
                and reduce exceptions to near zero.
              </p>
              <p className="lead">
                This means that you can be confident that when we provide you
                with an analysis, it is the most complete picture possible.
              </p>
              <p className="feature-action">
                <a href="/contact/">
                  Contact our support team{" "}
                  <FontAwesomeIcon className="" icon={faArrowCircleRight} />
                </a>
              </p>
            </div>
            <div className="col-md-5 order-md-1">
              <div className="row">
                <div className="col align-self-start"></div>
                <div className="col align-self-center iconMortalMachineCol">
                  <FontAwesomeIcon
                    className="featurette-image img-fluid iconHighlight iconMortalMachine fingerprint"
                    icon={faFingerprint}
                  />
                  <FontAwesomeIcon
                    className="featurette-image img-fluid iconHighlight iconMortalMachine plus"
                    icon={faPlus}
                  />
                  <FontAwesomeIcon
                    className="featurette-image img-fluid iconHighlight iconMortalMachine robot"
                    icon={faRobot}
                  />
                </div>
                <div className="col align-self-end"></div>
              </div>
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row featurette">
            <div className="col-md-7">
              <h3 className="featurette-heading">
                Tailored Reporting.
                <br />
                <span className="text-muted">
                  Fully customisable reporting engine.
                </span>
              </h3>
              <p className="lead">
                Our industry leading reports will give you everything you need,
                but we also know that each business has their own unique
                requirements. That's why our reporting engine is completely
                flexible and with just a few clicks you can customise layouts to
                exactly fit your specific needs.
              </p>
              <p className="feature-action">
                <a href="/contact/">
                  Request a demonstration{" "}
                  <FontAwesomeIcon className="" icon={faArrowCircleRight} />
                </a>
              </p>
            </div>

            <div className="col-md-5 mb-auto mt-auto mx-auto text-center">
              <FontAwesomeIcon
                className="featurette-image img-fluid iconHighlight icon-orange"
                icon={faChartPie}
              />
              <FontAwesomeIcon
                className="featurette-image img-fluid iconHighlight icon-blue"
                icon={faChartBar}
              />
            </div>
          </div>
        </div>

        <section className="jumbotron p-5 mt-5 mb-5 text-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-1 col-sm-2">
                <FontAwesomeIcon
                  className="iconQuote"
                  pull="left"
                  icon={faQuoteLeft}
                />
              </div>
              <div className="col-lg-10 col-sm-8">
                <p className="display-6">
                  <em>
                    I have no doubt that the accuracy and simplicity of your
                    analysis and of course the ease of working with you have
                    enabled us to win more new orders than we ever dreamt
                    possible.
                  </em>
                </p>
              </div>
              <div className="col-lg-1 col-sm-2">
                <FontAwesomeIcon
                  className="iconQuote"
                  pull="right"
                  icon={faQuoteRight}
                />
              </div>
            </div>
            <div className="row">
              <span className="text-muted">
                <em></em>
              </span>
            </div>
          </div>
        </section>

        <section className="jumbotron p-5 mt-5 mb-5 text-center">
          <div className="container">
            <div className="row featurette">
              <div id="support" className="col-lg-6 order-lg-2">
                <h3 className="featurette-heading mx-2">
                  Support when you need it.
                </h3>
                <p className="lead">
                  We don't use call centers or pre-written scripts so you'll get
                  straight through to one of the team. We're hands on with data
                  everyday so if you've got a question, we've probably got the
                  answer.
                </p>
                <p className="lead">Call us on +44 (0)1732 465 675</p>
              </div>
              <div className="col-lg-6 col-md-12 order-lg-1 mt-4">
                <img
                  className="support-team img-fluid"
                  src={support}
                  alt="Support Team"
                ></img>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </React.Fragment>
    );
  }
}

export default Home;
