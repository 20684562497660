import React from 'react';
import Footer from '../components/footer';

class Terms extends React.Component {

render () {
    return <React.Fragment>
        <div className="splash">

            <div className="splashBackground documents bg-dark d-flex">
            </div>

            <div className="cover-container position-relative flex-column text-left text-white">
            <h1 className="display-5">Our team has been delivering value to the telecoms industry since 2004.</h1>
            <p className="display-4 text-tmo">People. Communication. Innovation.</p>
            </div>


        </div>

        <div className="container mb-5">

            <div className="row featurette">
                <div className="col-lg-12">
                    <h3 className="featurette-heading">Terms &amp; Conditions</h3>

                    <div id="TermsAndConditions">

                        <p>By logging in to Tariffmatch.com “the Site” you agree to the Terms &amp; Conditions. If you do not agree then you should not log into the site, you should contact Tariffmatch Global Limited. </p>
                        
                        <p>Tariffmatch Global Limited reserves the right at its sole discretion to change, modify, add or remove sections of the Terms &amp; Conditions. Your continued use of the site, following any changes will mean that you accept and agree to the changes, as long as you comply with the Terms &amp; Conditions and have been granted a log in you have unlimited use of the site. If there is any conflict between these Terms and Conditions and the Data Processing Agreement, the provisions of the Data Processing Agreement shall take precedence.</p>
                        
                        <br />

                        <h2>1.	Content</h2>

                        <p>All data coding, text, reports, graphics, user interfaces, visuals, trademarks and computer code (collectively “content”) content contained on the site is owned and controlled by Tariffmatch Global Limited and is protected by Intellectual Property rights, copyright, patent and trade mark laws.</p>

                        <p>No part of the site, or content, may be copied, reproduced, publically displayed, transmitted or distributed to another website without the prior written consent of the Directors of Tariffmatch Global Limited.</p>
                        
                        <p>Users may access the reports, services &amp; products, which their log in allows them to access, for commercial information purposes and for verification of output.</p>
                        
                        <p>Users can also modify reports to meet their individual requirements and run additional tariffs in order to optimise the analytics within the site for their purposes / business requirements.</p>
                        
                        <br />
                        
                        <h2>2.	Levels of service / your use of the site</h2>

                        <p>You shall be responsible for ensuring the accuracy of tariffs and the full list of calls logged during the relevant period relating to your customers. Tariffmatch accepts no responsibility and will not indemnify against claims for any losses, resulting from the failure by you to provide correct data input.</p>

                        <p>Tariffmatch reserve the right to update and change the format of the reports from time to time and will notify users by way of our website.</p>

                        <p>As part of our service agreement you shall be entitled to upload data for analysis 24/7 onto the Tariffmatch website except during periods of outage which are outside of our control. We will publish your bill online ready for analysis within 5 working days after you have uploaded all relevant data.</p>

                        <p>Tariffmatch will perform the Services which you have signed up to and are detailed within your company’s Definitions of Service document which is “to perform services to each other resulting in an expert audit, reports and analytics for acquisition, increasing and retaining revenue of your existing customers”.</p>

                        <p>Tariffmatch employs qualified and experienced personnel to carry out the services or tasks relating to the provision of the services on the site.</p>

                        <p>You agree not to use any device, software or interface which could disrupt the working of the site.</p>

                        <br />

                        <h2>3.	Intellectual Property Rights</h2>

                        <p>As the creators and owners of Tariffmatch Global Limited all the IP on this site is the result of our human intellectual creativity and as such we do not give any user consent to copy or pass on to anyone or a third party either in the UK or globally the coding, trademarks, graphics, text, user interfaces, visual interfaces, reports, design, structure, selection coordination, look and feel of our site, such content contained on the site is owned, controlled by Tariffmatch and is protected by copyright, patent and trademark laws.</p>

                        <br />

                        <h2>4.	Password / Security</h2>

                        <p>Access is only available by authorised users through their individual unique log in and password containing dual authentication process.</p>

                        <p>You are responsible for the confidentiality you hold for your account including your password. Any activity that occurs under your log in as a result of failing to keep your information secure, or allowing unauthorised use of your log in / password will be deemed a breach of security and you will be held liable for any losses incurred by Tariffmatch as a result of you failing to keep your information secure &amp; confidential. You may not use anyone else’s log in or password. </p>
                                
                        <br />
                        
                        <h2>5.	Confidentiality / Data Protection</h2>

                        <p>Tariffmatch complies with the General Data Protection Regulation (GDPR) Act that came into force in May 2018 and will keep all your business customers information in the strictest confidence at our secure Hosting site at the Bunker. Our new upload system uses Microsoft Azure infrastructure and then forwards to the Bunker for processing.</p>

                        <p>We will also implement any additional GDPR requirements which apply. Under these rules we continue to be responsible as a Data Processor once we receive the information uploaded into our secure automated system. By uploading your data through our secure portal we have the consent to analyse the data contained within the upload in accordance with your requirements by way of your company’s Data Processing Agreement with Tariffmatch.</p>
                        
                        <br />

                        <h2>6.	Governing  Law</h2>

                        <p>You agree that all matters relating to the use and access of the site are governed by, the laws of England and Wales. You irrevocably agree to submit to the exclusive jurisdiction of the courts of England and Wales.</p>

                        <p>If you are a user based in any other Country you also agree that the governing law will be exclusively resolved through the jurisdiction of the courts of England and Wales and not the country that you reside in.</p>
                    

                        <p>This document was last updated on the <strong>24 August 2021</strong></p>

                    </div>
                </div>
            </div>
        </div>

    <Footer />
    
    </React.Fragment>;
}

}

export default Terms;