import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import logonotext from "../images/tariffmatch-logo-notext.svg";
import teamSmall from "../images/team-small.jpg";

import Footer from "../components/footer";

class About extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="splash">
          <div className="splashBackground team bg-dark d-flex"></div>

          <div className="cover-container position-relative flex-column text-left text-white">
            <h1 className="display-5">
              Our team has been delivering value to the telecoms industry since
              2004.
            </h1>
            <p className="display-4 text-tmo">
              People. Communication. Innovation.
            </p>
          </div>
        </div>

        <div className="container mb-5">
          <div className="row featurette">
            <div className="col-lg-7">
              <h3 className="featurette-heading">Who are we?</h3>
              <p className="lead">
                A leading technology company, incorporating a highly skilled
                team of business, technical and telecommunication specialists,
                we have years of experience working with Sales, Pricing,
                Commercial and Leadership teams within some of the largest
                telecommunications providers in the world. This has allowed us
                to create a suite of intelligent systems and services which can
                be fully integrated within Carrier ERP systems to automate,
                enhance and accelerate their business processes and the customer
                lifecycle.
              </p>
              <p className="lead">
                Our consultancy services are used by providers to allow their
                customers, under NDA, to assist with facilitating complex
                offerings and business transformations, helping them provide the
                best solutions for their customers.
              </p>
            </div>
            <div className="col-lg-5 mb-auto mt-auto py-5 text-center">
              <img src={logonotext} alt="Tariffmatch Logo" height="180px"></img>
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row featurette">
            <div className="col-lg-6 order-md-1 order-lg-2">
              <h3 className="featurette-heading">Team Tariffmatch</h3>
              <p className="lead">
                We believe that every team member here at Tariffmatch is an
                important part of something much bigger than their individual
                role. Through collaboration and playing to our collective
                strengths we can achieve anything.
              </p>
              <p className="lead">
                As a company we strive to help everyone develop and progress
                towards their individual career goals. We fully support remote
                and flexible working for everyone, nurture with training and
                development, and whether in the office or remotely, everyone has
                a voice to offer honest and productive feedback.
              </p>
              <p className="lead">
                We're proud to have a team with such drive and determination,
                whatever the challenge.
              </p>

              <p className="feature-action">
                <a href="/contact/">
                  If you think we might be a good fit for you, why not get in
                  touch.{" "}
                  <FontAwesomeIcon className="" icon={faArrowCircleRight} />
                </a>
              </p>
            </div>
            <div className="col-lg-6 col-md-12 order-md-2 order-lg-1 mb-auto mt-5 px-5 mx-auto text-center">
              <img
                className="team-tariffmatch img-fluid"
                alt="Team Working"
                src={teamSmall}
              ></img>
            </div>
          </div>
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}

export default About;
