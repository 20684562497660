import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Joi from "joi";
import http from "../services/httpService"
import { Modal, Button } from 'react-bootstrap';

import Footer from '../components/footer';

const apiEndPoint =
  process.env.REACT_APP_APIGATEWAY + "CommunicationAPI/api/v1/ContactUs/";

const unSubscribeFormURL =
  process.env.REACT_APP_UNSUBSCRIBE;

class About extends React.Component {

state = {
  data: { firstName:"", lastName: "", companyName: "", telephoneNumber: "", emailAddress: "", message: "", communicationOptIn: false },
  errors: {},
  errorList: [],
  showSubmitted: false
}

schema = Joi.object({
  firstName: Joi.string().label("First Name").min(2).max(50).options({ errors: { wrap: { label: false } } }),
  lastName: Joi.string().label("Last Name").min(2).max(50).options({ errors: { wrap: { label: false } } }),
  companyName: Joi.string().label("Company Name").min(2).max(50).options({ errors: { wrap: { label: false } } }),
  telephoneNumber: Joi.string().label("Telephone Number").allow('').regex(RegExp("^[+]?[0-9]{10,15}$")).max(20).messages({
    'string.pattern.base': '{#label} must be valid', // --> Email must be valid.
    'string.base': '{#label} should be a type of "text"'
  }).options({ errors: { wrap: { label: false } } }),
  emailAddress: Joi.string().label("Email Address").email({ tlds: { allow: false }}).max(255).options({ errors: { wrap: { label: false } } }),
  message: Joi.string().label("Message").min(2).max(2000).options({ errors: { wrap: { label: false } } }),
  communicationOptIn: Joi.boolean()
});

validate = () => {
  const result = this.schema.validate(this.state.data);

  if (!result.error) return null;

  const errors = {};

  for (let item of result.error.details) errors[item.path[0]] = item.message;
  return errors;
};

validateProperty = ({ name, value }) => {
  const obj = { [name]: value };
  const { error } = this.schema.validate(obj);
  return error ? error.details[0].message : null;
};

handleChange = ({ currentTarget: input }) => {
  const errors = JSON.parse(JSON.stringify(this.state.errors));
  const errorMessage = this.validateProperty(input);
  if (errorMessage) errors[input.name] = errorMessage;
  else delete errors[input.name];

  const data = JSON.parse(JSON.stringify(this.state.data));
  data[input.name] = input.value;
  
  this.setState({ data, errors });
};


handleCheck = ({ currentTarget: input }) => {
  const data = JSON.parse(JSON.stringify(this.state.data));
  data[input.name] = input.checked;
  this.setState({ data });
};

handleSubmit = e => {
  e.preventDefault();

  const errors = this.validate();
  this.setState({ errors: errors || {} });
  if (errors) return;

  this.doSubmit();
};

clearSendMessageForm () {
  let data = this.state.data;
  
  data.firstName = "";
  data.lastName = "";
  data.companyName = "";
  data.telephoneNumber =  "";
  data.emailAddress =  "";
  data.message =  "";
  data.communicationOptIn = false;

  this.setState(data);
}

doSubmit = async () => {
  
  try {

    var errorList = this.state.errorList;

    errorList.length = 0;

    this.setState({errorList});

    const message = { firstName: this.state.data.firstName, lastName: this.state.data.lastName, companyName: this.state.data.companyName,
      telephone: this.state.data.telephoneNumber, email: this.state.data.emailAddress, message: this.state.data.message, communicationOptIn: this.state.data.communicationOptIn }

    await http.post(apiEndPoint, message, {withCredentials:false});
    
    const submitSuccess = true;

    this.clearSendMessageForm();

    this.setState({showSubmitted: submitSuccess})

  }
  catch (error) {

    if((error.response) && error.response.data.status === 400){
         
      errorList = error.response.data.errors.ContactUs;

      this.setState({errorList});

    }
    else{

        const error = "Something went wrong. Please try later."

        errorList = this.state.errorList;

        errorList.push(error);

        this.setState({errorList});

    }
     
  }

};

hideSubmittedModal () {

  const showSubmitted = false;

  this.setState({showSubmitted});
}

render () {
    return <React.Fragment>
        
        <div className="splash">
            <div className="splashBackground contact bg-dark d-flex">
            </div>

            <div className="cover-container position-relative flex-column text-left text-white">
            <h1 className="display-5">We'd love to hear from you, whatever the reason.</h1>
            <p className="display-4 text-tmo">Enquire. Feedback. Support.</p>
            </div>
        </div>

        <div className="container mb-5">

            <div className="row">
                <div className="col-md-8">
                    <h3 className="featurette-heading">Getting in touch</h3>
                    <p className="lead">Our office hours are 09:00 till 17:00 Monday to Friday.</p>
                    <p className="lead">If you'd like to contact us outside of these hours or would prefer to send us a message, please use the form below.</p>
                </div>
                <div className="col-md-4 mb-auto mt-auto mx-auto text-center">
                  
                </div>
            </div>


            <div className="row">


              <div className="col-md-4 col-lg-4 text-center order-md-2">
                <h2 className="display-6 pb-2">Give us a call <FontAwesomeIcon className='img-fluid iconContact icon-orange' icon={faComments} /></h2>
                <p>If you have an enquiry please give us a call</p>
                <h5>Enquiries</h5>
                <p className="display-7">+44 (0)1732 465 675</p>

                
                <h2 className="display-6 pt-5">Find Us <FontAwesomeIcon className='img-fluid iconContact icon-green' icon={faMapMarkerAlt} /></h2>
                <p className="px-5">Our offices are situated on the slope of the Greensand ridge in beautiful northwest Kent.</p>
                <div className="pb-5">
                  <h5>Office Address</h5>
                  Tariffmatch Global Limited
                  <br />
                  St Julians
                  <br />
                  Sevenoaks
                  <br />
                  Kent TN15 0RX
                </div>
              </div>

 
              <div className="col-md-8 col-lg-8 order-md-1">
                <h2 className="display-6">Send us a message</h2>
                
                <form className="needs-validation" onSubmit={this.handleSubmit}>
                  
                  <div className="row g-3">
                    
                    <div className="col-sm-6">
                      <label htmlFor="firstName" className="form-label">First name</label>
                      <input type="text" name="firstName" className="form-control" onChange={this.handleChange} id="firstName" placeholder="" value={this.state.data.firstName} error={this.state.errors["firstName"]} />
                      {this.state.errors["firstName"] && <div className="alert alert-tm">{this.state.errors["firstName"]}</div>}
                    </div>

                    <div className="col-sm-6">
                      <label htmlFor="lastName" className="form-label">Last name</label>
                      <input type="text" name="lastName" className="form-control" id="lastName" onChange={this.handleChange}  placeholder="" value={this.state.data.lastName} error={this.state.errors["lastName"]}   />
                      {this.state.errors["lastName"] && <div className="alert alert-tm">{this.state.errors["lastName"]}</div>}
                    </div>

                    <div className="col-sm-6">
                      <label htmlFor="email" className="form-label">Company Name</label>
                      <input type="text" name="companyName" className="form-control" id="companyName" onChange={this.handleChange}  placeholder="" value={this.state.data.companyName} error={this.state.errors["companyName"]} />
                      {this.state.errors["companyName"] && <div className="alert alert-tm">{this.state.errors["companyName"]}</div>}
                    </div>

                    <div className="col-sm-6">
                      <label htmlFor="telephone" className="form-label">Telephone</label>
                      <input type="telephone" name="telephoneNumber" className="form-control" id="telephoneNumber" onChange={this.handleChange} placeholder="" value={this.state.data.telephoneNumber} error={this.state.errors["telephoneNumber"]} />
                      {this.state.errors["telephoneNumber"] && <div className="alert alert-tm">{this.state.errors["telephoneNumber"]}</div>}
                    </div>

                    <div className="col-12">
                      <label htmlFor="email" className="form-label">Email</label>
                      <input type="email" name="emailAddress" className="form-control" id="emailAddress" onChange={this.handleChange} value={this.state.data.emailAddress} error={this.state.errors["emailAddress"]} placeholder="you@example.com" />
                      {this.state.errors["emailAddress"] && <div className="alert alert-tm">{this.state.errors["emailAddress"]}</div>}
                    </div>

                    <div className="col-12">
                      <label htmlFor="message" className="form-label">Your Message</label>
                      <textarea className="form-control" name="message" id="message" onChange={this.handleChange} value={this.state.data.message} error={this.state.errors["message"]} rows="5"></textarea>
                      {this.state.errors["message"] && <div className="alert alert-tm">{this.state.errors["message"]}</div>}
                    </div>

                    <div id="errorList" style={{display: this.state.errorList.length > 0 ? 'block' : 'none'}} className="alert alert-tm mt-4 mb-0" role="alert">
                        <div>The following errors occured.</div>
                        <ul>
                            {this.state.errorList.map(error => {
                                return(
                                    <li>{error}</li>
                                )
                            })}
                        </ul>
                    </div>

                    <hr className="mt-4 mb-1" />

                    <div className="col-12">
                      <label htmlFor="communicationOptIn" className="form-label mb-4">We will use your email address to contact you in response to this enquiry. To receive additional news and updates from us in the future please tick the box below.</label>
                      <br />
                      <input type="checkbox" name="communicationOptIn" onChange={this.handleCheck} className="form-check-input mx-3" checked={this.state.data.communicationOptIn} id="communicationOptIn" />
                      <label className="form-check-label mb-3" htmlFor="communicationOptIn">Subscribe to our email updates?</label>
                      <p className="text-muted"><small>By checking this box, you agree that Tariffmatch Global Ltd may send you marketing e-mails about the products and services they provide. Any data collected will be handled and processed in accordance with our <a href="/privacy-policy" target="_Blank" rel="noreferrer">privacy policy</a>. You can change your mind and unsubscribe at any time by using this <a href={unSubscribeFormURL} target="_Blank" rel="noreferrer">unsubscribe form</a>.</small></p>
                    </div>

                    <div className="col-12">
                      <button className="w-100 btn btn-orange btn-lg" disabled={this.validate()} type="submit">Send</button>
                    </div>

                  </div>

                </form>



                <Modal aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={this.state.showSubmitted}>

                  <Modal.Body>
                    <p>Thank you for your message.</p>
                    <p>We will be in touch soon.</p>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button variant="secondary" onClick={() => this.hideSubmittedModal()}>Close</Button>
                  </Modal.Footer>
                </Modal>

              </div>


            </div>

    </div>

    <Footer />

    </React.Fragment>;
}

}

export default About;