import $ from "jquery";
import {Buffer} from 'buffer';

export default function noSpam() {
  $("a#em-enquiries").on("click", function (e) {
    var secret = function () {
      return Buffer.from("ZW5xdWlyaWVzQHRhcmlmZm1hdGNoLmNvbQ==", "base64");
    };
    $(this).attr("href", "mailto:" + secret());
    $(this).text(secret());
    $(this).off("click");
    e.preventDefault();
  });

  $("a#em-subjectaccess").on("click", function (e) {
    var secret = function () {
      return Buffer.from(
        "c3ViamVjdGFjY2Vzc3JlcXVlc3RAdGFyaWZmbWF0Y2guY29t",
        "base64"
      );
    };
    $(this).attr("href", "mailto:" + secret());
    $(this).text(secret());
    $(this).off("click");
    e.preventDefault();
  });

  $("a#em-erasurerequest").on("click", function (e) {
    var secret = function () {
      return Buffer.from("ZXJhc3VyZXJlcXVlc3RAdGFyaWZmbWF0Y2guY29t", "base64");
    };
    $(this).attr("href", "mailto:" + secret());
    $(this).text(secret());
    $(this).off("click");
    e.preventDefault();
  });

  $("a#em-complaints").on("click", function (e) {
    var secret = function () {
      return Buffer.from("Y29tcGxhaW50c0B0YXJpZmZtYXRjaC5jb20=", "base64");
    };
    $(this).attr("href", "mailto:" + secret());
    $(this).text(secret());
    $(this).off("click");
    e.preventDefault();
  });

  $("a#em-consent").on("click", function (e) {
    var secret = function () {
      return Buffer.from("Y29uc2VudEB0YXJpZmZtYXRjaC5jb20=", "base64");
    };
    $(this).attr("href", "mailto:" + secret());
    $(this).text(secret());
    $(this).off("click");
    e.preventDefault();
  });
}
