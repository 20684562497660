import React from "react";

import Footer from "../components/footer";
import noSpam from "../utilities/noSpam";

class Privacy extends React.Component {
  componentDidMount() {
    noSpam();
  }

  render() {
    return (
      <React.Fragment>
        
        <div className="splash">
          <div className="splashBackground documents bg-dark d-flex"></div>

          <div className="cover-container position-relative flex-column text-left text-white">
            <h1 className="display-5">
              Our team has been delivering value to the telecoms industry since
              2004.
            </h1>
            <p className="display-4 text-tmo">
              People. Communication. Innovation.
            </p>
          </div>
        </div>

        <div className="container mb-5">
          <div className="row featurette">
            <div className="col-lg-12">
              <h3 className="featurette-heading">Privacy Policy</h3>

              <div id="PrivacyPolicy">
                <p>
                  This privacy policy will help you understand what information
                  we collect and use at Tariffmatch, how we protect the
                  information we hold and the choices you have associated with
                  that information.
                </p>
                <p>
                  Where we refer to “Tariffmatch”, “we”, or “us” in this policy,
                  we are referring to Tariffmatch Global Ltd which provides the
                  services to you and your organisation.
                </p>
                <p>
                  The “Services” refers to the applications, services and
                  website provided by Tariffmatch.
                </p>
                <p>
                  Tariffmatch may from time to time introduce new products and
                  services. To the extent that these new products and services
                  affect this policy, we will notify you of any significant
                  changes, however you are welcome to come back and check it at
                  any time.
                </p>
                <p>
                  If you have any questions about this policy or our practices,
                  please contact us at{" "}
                  <a id="em-enquiries" href="/">
                    click to reveal
                  </a>
                  .
                </p>

                <br />

                <h2>Item 1. What information do we collect and why?</h2>

                <p>
                  <strong>
                    Item 1.1. Visitors to our website (non – registered users)
                  </strong>
                </p>

                <p>
                  Tariffmatch Global Ltd is the acting data controller for
                  information collected from the Tariffmatch website.
                </p>

                <p>
                  <strong>Item 1.1.1. Website Enquiries</strong>
                </p>

                <p>
                  When you submit an enquiry through our website, we collect
                  your contact details such as the ones listed below:
                </p>
                <ul>
                  <li>Full Name.</li>
                  <li>Company.</li>
                  <li>E-Mail Address.</li>
                  <li>Telephone Number.</li>
                </ul>

                <p>
                  We will use these details with your consent to contact you and
                  send you information in response to your enquiry.
                </p>

                <p>
                  If you have provided your consent and “opt-in”, we may also
                  send you sales &amp; marketing e-mails on the Tariffmatch
                  products and services from time to time. You can opt-out of
                  these using the “Unsubscribe links / functions” at any time.
                </p>

                <p>
                  <strong>
                    Item 1.2. Registered users &amp; employees of our contracted
                    customers
                  </strong>
                </p>

                <p>
                  Tariffmatch only collect and hold the necessary information
                  required, which is used in order to provide the agreed and
                  contracted services to you and our customers.
                </p>

                <p>
                  When you are using Tariffmatch analytics services, Tariffmatch
                  Global Ltd is acting as the joint data controller and
                  processer on behalf of your organisation.
                </p>

                <p>
                  <strong>Item 1.2.1. Account Management Information</strong>
                </p>

                <p>
                  We may collect and hold the following information either
                  directly from you or it may be passed to us by your
                  organisation so that we may create a login and account in
                  order for you to use our services:
                </p>
                <ul>
                  <li>Full Name.</li>
                  <li>Business Email Address.</li>
                  <li>Tariffmatch Username.</li>
                  <li>Password.</li>
                  <li>Business Phone Number .</li>
                  <li>Business Mobile Number.</li>
                  <li>Country &amp; Language.</li>
                  <li>Position / Job title.</li>
                  <li>Business Group / Department.</li>
                  <li>IP Address.</li>
                </ul>

                <p>
                  The information is used in order to authenticate you to our
                  services, for account management and for us to contact you in
                  conjunction with the services that we are providing to you or
                  your organisation.{" "}
                </p>

                <p>Examples of use:</p>
                <ul>
                  <li>
                    When logging in to our services, we may send a unique pin
                    code to your registered e-mail address in order to perform
                    multi-factor authentication.
                  </li>
                  <li>
                    We will send you notifications to your e-mail address and
                    mobile telephone number in order to send you updates on job
                    progress.
                  </li>
                  <li>
                    Our analytics team may contact you in regards to a specific
                    job in order to request further information or to resolve a
                    query.
                  </li>
                  <li>
                    Our support team may contact you in regards to support
                    issues.
                  </li>
                </ul>

                <p>
                  We may also use your contact details in order to supply you
                  with important information about our products and services
                  such as:
                </p>
                <ul>
                  <li>
                    Training and instructional materials to help you get the
                    most from the services we provide, including invites to
                    webinar and training sessions.
                  </li>
                  <li>
                    Information on functionality updates and changes to existing
                    products and services.
                  </li>
                  <li>
                    Information about additional tools, products, features and
                    services that we introduce as part of your subscription.
                  </li>
                </ul>

                <p>
                  Every 90 days we request that you review the personal
                  information such as your contact details to ensure the
                  information we hold about you is correct. Our systems will
                  automatically prompt you after login and you should take time
                  to review this information carefully and correct any details
                  where necessary.
                </p>

                <p>
                  <strong>Item 1.2.2. Usage information</strong>
                </p>

                <p>
                  When you use our sites and services, Tariffmatch collects
                  information about the pages you visit and the analytical and
                  reporting functions &amp; services used.{" "}
                </p>

                <p>
                  Examples of the information we collect for auditing purposes:
                </p>
                <ul>
                  <li>UserID/ UserName.</li>
                  <li>Page Name.</li>
                  <li>Request Date Time.</li>
                  <li>Function Description.</li>
                  <li>IP Address.</li>
                </ul>

                <p>This information may be used for the following reasons:</p>

                <p>
                  <strong>Security:</strong> Our systems monitor user activity
                  in order to detect any unusual or suspicious activity. This
                  information is used to both help prevent and investigate any
                  information security incidents or data breaches.{" "}
                </p>
                <p>
                  <strong>Support:</strong> Activity logs help our support and
                  technical teams deliver support to you and your organisation.
                  The logs may help to identify how and why a particular issue
                  has occurred.
                </p>
                <p>
                  <strong>Capacity Management:</strong> Activity logs help our
                  systems and engineers monitor and manage load on our sites,
                  services and processing systems.
                </p>

                <p>
                  We may also collect and record information about the analytics
                  jobs you run such as the following, in order to provide usage
                  statistics to you and your organisation:
                </p>
                <ul>
                  <li>Customer.</li>
                  <li>Bill.</li>
                  <li>Tariff.</li>
                  <li>Processing Author.</li>
                  <li>Existing Spend.</li>
                  <li>Projected Spend.</li>
                  <li>Processing Date Time.</li>
                  <li>Margin.</li>
                  <li>Margin %.</li>
                  <li>Connection Types and Quantities.</li>
                  <li>
                    Total usage data according to traffic type e.g Mins,
                    Megabytes, Messages.
                  </li>
                  <li>
                    Total line or channel quantities according to line or
                    service type.
                  </li>
                </ul>

                <p>
                  <strong>Item 1.2.3. Customer Data</strong>
                </p>

                <p>
                  When you submit customer billing to us on behalf of your
                  organisation, we ask you for and collect information about the
                  customer, which may include information such as:
                </p>

                <ul>
                  <li>Business Name.</li>
                  <li>Branch Name(s).</li>
                  <li>Country.</li>
                  <li>Industry Sector.</li>
                  <li>Primary Business Contact Name.</li>
                  <li>Primary Business Contact E-mail.</li>
                  <li>Business Address.</li>
                </ul>

                <p>
                  We collect and use this information for the following reasons:
                </p>

                <p>
                  <strong>Reporting:</strong> So that the customer and branch
                  name can be displayed on the reporting that you and our
                  systems generate for this customer.
                </p>
                <p>
                  <strong>Organisation:</strong> Tariffmatch systems allow you
                  to analyse different types of billing and produce various
                  different types of reports for customers. Our system groups
                  and organises all of this information by customer so it is
                  easier for you to work with.
                </p>
                <p>
                  <strong>Usage Statistics:</strong> We may also use customer
                  details such as the customer name and industry sector in order
                  to provide categorised opportunity based usage statistics to
                  you and your organisation.
                </p>

                <p>
                  <strong>Item 1.2.4. Customer Itemised Billing Data</strong>
                </p>

                <p>
                  When you submit customer billing to us for analysis, we
                  collect and use the information contained within the
                  electronic data files to provide the analysis and reporting
                  services and functions requested and we are contracted to
                  provide to you or your organisation.
                </p>

                <p>
                  Depending on the type of electronic billing and the
                  origination of the electronic data, the information contained
                  in the files may vary, however the following information is
                  normally used and collected as part of our analysis:
                </p>

                <p>
                  <strong>Item 1.2.4.1. Itemised Call Data</strong>
                </p>
                <ul>
                  <li>Origination CLI / Number.</li>
                  <li>Billing Account Name.</li>
                  <li>Billing Account Number.</li>
                  <li>Billing Entity Number.</li>
                  <li>Destination / Dialled Number.</li>
                  <li>Call Time.</li>
                  <li>Call Cost.</li>
                  <li>Call Duration.</li>
                  <li>
                    Providers Call or Item Description, Category /
                    Classification.
                  </li>
                  <li>Call / Traffic Type.</li>
                  <li>Originating Country.</li>
                  <li>Network.</li>
                </ul>

                <p>
                  <strong>Item 1.2.4.2. Invoice Data</strong>
                </p>
                <ul>
                  <li>Origination CLI / Number.</li>
                  <li>Billing Account Name.</li>
                  <li>Billing Account Number.</li>
                  <li>Billing Entity Number.</li>
                  <li>Description / Username.</li>
                  <li>Rental Period.</li>
                  <li>Rental Date.</li>
                  <li>Rental Charges.</li>
                  <li>Rental Discounts.</li>
                  <li>Price Plan.</li>
                  <li>Tariff.</li>
                  <li>Cost Centre / Reporting Level.</li>
                  <li>Item Description.</li>
                  <li>Cost.</li>
                  <li>Discounts.</li>
                  <li>Invoice Totals.</li>
                </ul>

                <p>
                  <strong>Item 1.2.4.3. Line Rental Data</strong>
                </p>
                <ul>
                  <li>Origination CLI / Number.</li>
                  <li>Line, Item, Service Type.</li>
                  <li>Line, Item, Service Description.</li>
                  <li>Rental Period.</li>
                  <li>Rental Date.</li>
                  <li>Quantity.</li>
                  <li>Cost.</li>
                  <li>Discounts.</li>
                </ul>

                <p>
                  Tariffmatch systems and data analysts use the itemised
                  information contained in these files in order to identity all
                  the lines, connections, items and services to a granular level
                  and associated charges.{" "}
                </p>

                <p>
                  Detailed reporting and itemised tariff comparisons will be
                  produced on the data by both Tariffmatch systems and through
                  use of the Tariffmatch analytics tools in conjunction with the
                  contracted services that we provide to you and your
                  organisation.
                </p>

                <p>
                  All customer electronic billing data, itemised call data files
                  and all associated reports will be held for a maximum
                  retention period of 12 months upon receipt / upload of the
                  data, after which, the data will be securely deleted
                  automatically.
                </p>

                <p>
                  Certain associated meta-data, usage and audit data associated
                  with the customer billing may be retained after this period
                  for audit, security purposes and used to provide usage
                  statistics to your organisation.
                </p>

                <p>
                  <strong>
                    Item 1.3. Registered and non-registered users of our sites
                    and services
                  </strong>
                </p>

                <p>
                  <strong>Item 1.3.1. Cookies</strong>
                </p>

                <p>
                  Cookies are very small text files that are stored on your
                  computer when you visit certain web pages. We use cookies on
                  our site to:
                </p>

                <ul>
                  <li>Help you navigate our website.</li>
                  <li>
                    Facilitate the sign up and login process for our services.
                  </li>
                  <li>Personalize your experience.</li>
                  <li>Analyse which pages our site visitors visit.</li>
                  <li>Measure advertising and promotional effectiveness.</li>
                </ul>

                <p>
                  Videos and other multimedia features, used on our site for
                  delivery of training material and video guides may also rely
                  on cookies to collect and store your preferences. These
                  cookies are different from browser cookies because of the
                  amount of, type of, and how data is stored.{" "}
                </p>

                <p>
                  Please note that some cookies may be placed by a third party
                  service provider who performs some of these functions for us.
                </p>

                <br />
                <h2>Item 2. Information Sharing</h2>

                <p>
                  The information and data that we hold about you, our users, is
                  held in the strictest of confidence, however in certain
                  circumstances (e.g by law) we may be required to disclose or
                  provide access to your personally identifying information to a
                  third party. We will only disclose this information:
                </p>
                <ul>
                  <li>
                    As required by law such as to comply with a subpoena, or
                    similar legal process to the extent we are legally permitted
                    to do so. We will take reasonable steps to notify you in the
                    event that we are required to provide your personal
                    information to third parties as part of legal process.
                  </li>
                  <li>
                    When we believe in good faith that disclosure is necessary
                    to protect our rights, protect your safety or the safety of
                    others, investigate fraud or respond to a written government
                    request.
                  </li>
                  <li>
                    If Tariffmatch becomes involved in a merger, acquisition, or
                    any form of sale of some or all of its assets, we will
                    provide notice before personally identifying information
                    becomes subject to a different privacy statement.
                  </li>
                </ul>

                <p>
                  We will share your personally identifying information with
                  third parties only in the ways that are described in this
                  Privacy Statement. We do not otherwise sell your personally
                  identifying information to third parties.
                </p>

                <br />
                <h2>Item 3. Data Protection</h2>

                <p>
                  Protection of our customers and users data is of the utmost
                  importance to us and something that we take very seriously.
                  All Tariffmatch services and data are protected both digitally
                  and physically.
                </p>

                <p>
                  We secure access to all transactional areas of our websites
                  and apps including our secure data upload portal using
                  industry recognised ‘https’ SSL SHA2-256 with RSA encryption.
                </p>

                <p>
                  Tariffmatch and its employees follow industry standards for
                  information security best practice and implement a structured
                  information security framework which is continuously updated
                  and reviewed.
                </p>

                <p>
                  Our servers, data and services are held in and provided from
                  one of the UK's highest security data centres. Our data centre
                  provides security features including but not limited to:
                </p>

                <ul>
                  <li>ISO 27001 accredited.</li>
                  <li>
                    24/7 monitoring of the data centre via a manned security
                    desk.
                  </li>
                  <li>
                    Full access control via a manned security desk and coded
                    security passes.
                  </li>
                  <li>In depth CRB check of all data centre employees.</li>
                  <li>
                    Built to withstand a one megaton near miss missile strike.
                  </li>
                  <li>
                    Built above flood plains and on rubber dampers to withstand
                    earthquakes.
                  </li>
                  <li>
                    Located 100ft underground with one external blast door, 5
                    internal blast doors.
                  </li>
                  <li>
                    Bio-chemical doors to protect and seal the inner facility
                    restricting airborne radiation and bio-chemical risks.
                  </li>
                  <li>
                    Power and network access provided via subterranean ducts.
                  </li>
                  <li>Protected by a Faraday cage with EMP shielding.</li>
                  <li>
                    All network and power services are protected in locked
                    cages.
                  </li>
                  <li>
                    All server cabinets are locked with keys held at a manned
                    security desk. Keys are signed out and recorded on a
                    register.
                  </li>
                  <li>
                    The facility operates a warm/cold isle configuration. Each
                    room within the facility has multiple CRACK units with
                    cooling provided on a N+1 basis.
                  </li>
                  <li>
                    Two subterranean 11,000v power supplies each fed into a
                    separate sub station on premis, each with its own 250,000l
                    capacity generator providing power for up to 3 months.
                  </li>
                  <li>
                    Five banks of UPS providing 45 minutes of power until the
                    generators take over.
                  </li>
                </ul>

                <p>
                  Servers and data protected by sophisticated firewalls with
                  stateful inspection of data packets.
                </p>

                <p>
                  Systems and networks are monitored by sophisticated security
                  systems and software for malware, virus, ransomware and
                  intrusion, including protection against potential zero day
                  attacks.
                </p>

                <p>
                  Systems and servers designed with automatic load balancing and
                  high availability failover to ensure maximum uptime and
                  delivery of services.
                </p>

                <p>
                  Any data transferred by Tariffmatch outside the secure
                  environment for any reason is held encrypted with a minimum of
                  256bit encryption.
                </p>

                <p>
                  User access logging &amp; tracking is used to monitor access
                  to our services for security.
                </p>

                <p>
                  Error tracking and reporting systems constantly monitor
                  Tariffmatch systems &amp; services for any issues.
                </p>

                <p>
                  Health monitoring of all our servers and services by dedicated
                  monitoring hardware &amp; software.
                </p>

                <p>
                  State of the art multi-level and multi-site backup systems in
                  place to ensure business continuity and disaster recovery.
                </p>

                <p>
                  We use strong multi-level hashing algorithms for encrypting
                  user passwords to our services with the ability to enable
                  multi-factor authentication by your organisation to keep your
                  account secure.
                </p>

                <p>
                  Every 90 days we also request that you change / update your
                  account password. Our systems will automatically prompt you to
                  do this during login. Although we have implemented steps to
                  help you choose a secure password, it is your ultimate
                  responsibility to a) Choose a strong password that is not easy
                  for a third party to guess. b) To keep the password secure and
                  not to disclose this password to others. c) Not to re-use the
                  same password across other sites and services.{" "}
                </p>

                <br />
                <h2>Item 4. Your Rights</h2>

                <p>
                  <strong>Item 4.1. Subject Access Requests</strong>
                </p>

                <p>
                  At your request, we can provide you with details and a copy of
                  the personal information / data that we hold about you which
                  we will aim to deliver within 30 days of the request.
                </p>

                <p>
                  Tariffmatch will not charge for complying with a subject
                  access request.
                </p>

                <p>
                  We will aim to ensure that the data we will provide in
                  response to a subject access request will be delivered in a
                  structured, readable and widely compatible format.{" "}
                </p>

                <p>
                  Tariffmatch will explain their lawful basis for processing
                  data when answering a subject access request.
                </p>

                <p>
                  Requests may be refused only if they are manifestly unfounded
                  or excessive. On refusal of a request, you will be notified of
                  the reason within 30 days of the request and you have the
                  right to complain to the supervisory authority and to a
                  judicial remedy.
                </p>

                <p>
                  You can submit an access request by sending your contact
                  details and detailing the reason for the request to{" "}
                  <a id="em-subjectaccess" href="/">
                    click to reveal
                  </a>
                  .{" "}
                </p>

                <p>
                  <strong>Item 4.2. Consent</strong>
                </p>

                <p>
                  You have the right to withdraw consent at any time where
                  relevant. In order to withdraw consent, please send the
                  details of your request to{" "}
                  <a id="em-consent" href="/">
                    click to reveal
                  </a>{" "}
                </p>

                <p>
                  <strong>item 4.3. Right to be Forgotten</strong>
                </p>

                <p>
                  Under Article 17 of the GDPR individuals have the right to
                  have personal data erased. This is also known as the ‘right to
                  be forgotten’. The right is not absolute and only applies in
                  certain circumstances.
                </p>

                <p>You have the right to have your personal data erased if:</p>

                <ul>
                  <li>
                    Your personal data is no longer necessary for the purpose
                    which we collected or processed it for;
                  </li>
                  <li>
                    We are relying on consent as our lawful basis for holding
                    the data, and you, the individual withdraws consent;
                  </li>
                  <li>
                    We are relying on legitimate interests as our basis for
                    processing, you object to the processing of your data, and
                    there is no overriding legitimate interest to continue this
                    processing;
                  </li>
                  <li>
                    We are processing the personal data for direct marketing
                    purposes and you object to that processing;
                  </li>
                  <li>We have processed the personal data unlawfully.</li>
                  <li>We have to do it to comply with a legal obligation.</li>
                </ul>

                <p>
                  If you would like to submit an erasure request, please send
                  the details of your request to{" "}
                  <a id="em-erasurerequest" href="/">
                    click to reveal
                  </a>
                  . We will aim to respond to erasure requests within 30 days.
                </p>

                <p>
                  In order to comply with our policy requirements and contracted
                  terms for data protection and business continuity, your data
                  may reside in our backups for up to 12 months following
                  successful completion of an erasure request.{" "}
                </p>

                <p>
                  You can be assured that Tariffmatch take steps to ensure your
                  personal data will not be restored back to production systems
                  (except in certain rare instances, e.g., the need to recover
                  from a natural disaster or serious security breach). In such
                  cases, the personal data may be restored from backups, but we
                  will take the necessary steps to honour the initial request
                  and erase the primary instance of the data again.
                </p>

                <p>
                  Backup archives containing personal data will be protected
                  with strong encryption, so that even if criminals were able to
                  steal the archive, its contents would remain useless to them.
                </p>

                <p>
                  Retention rules have been put in place so that personal data
                  in backup archives is retained for as short a time as
                  necessary before being automatically deleted.
                </p>

                <p>
                  Records of all data subject requests regarding their personal
                  data will be retained, as will audit logs that record all
                  activities on backup archives containing personal data. This
                  means that you can be confident that your personal data has
                  been backed up in accordance with GDPR principles of security
                  by design and by default, as well as data minimization, and
                  that your rights, including the right to be forgotten, have
                  been honoured.
                </p>

                <p>
                  <strong>Item 4.4. Complaints</strong>
                </p>
                <p>
                  Without prejudice to any other administrative or judicial
                  remedy, every data subject has the right to lodge a complaint
                  with a supervisory authority, in particular in the Member
                  State of his or her habitual residence, place of work or place
                  of the alleged infringement if the data subject considers that
                  the processing of personal data relating to him or her
                  infringes this Regulation.
                </p>
                <p>
                  If you think your data has been misused or that it hasn’t kept
                  it secure, you should first contact us and tell us by sending
                  the details of your complaint to us at{" "}
                  <a id="em-complaints" href="/">
                    click to reveal
                  </a>
                  .
                </p>
                <p>
                  If you’re unhappy with our response or if you need any advice
                  you should contact the Information Commissioner’s Office
                  (ICO). More information on how to do this can be found here
                  https://www.gov.uk/data-protection/make-a-complaint
                </p>
                <p>
                  This document was last updated on the{" "}
                  <strong>17 May 2018</strong>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}

export default Privacy;
