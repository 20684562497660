import React from 'react';

import Footer from './footer';

class FourOhFour extends React.Component {

render () {
    return <React.Fragment>
        <div className="splash">

            <div className="splashBackground maze bg-dark d-flex">
            </div>

            <div className="cover-container position-relative flex-column text-left text-white">
            <h1 className="display-5">We can't find the page you are looking for.</h1>
            <p className="display-4 text-tmo">Sorry about that.</p>
            </div>


        </div>

        <div className="container mb-5">

            <div className="row featurette">
                <div className="col-lg-12">
                    <h3 className="featurette-heading">Sorry we can't find your page</h3>

                    <div id="PrivacyPolicy">

                        <p>
                            Please <a href="/">Click Here</a> to go to our homepage.
                        </p>
                        
                    </div>

                </div>
            </div>

        </div>


    <Footer />
    
    </React.Fragment>;
}

}

export default FourOhFour;