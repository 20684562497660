import React from 'react';
import logo from '../images/tariffmatch-logo.svg';

class Footer extends React.Component {

  render () {

    return <React.Fragment>

      <footer className="p-4 pt-md-5 border-top">
        
        <div className="container">

          <div className="row">
            
            <div className="col-md-8">
              <img className="mb-1 footer-logo" src={logo} alt="Tariffmatch Global Lmited" />
              <div className="mb-3">
                <br />
                Tariffmatch Global Limited
                <br />
                St Julians
                <br />
                Sevenoaks
                <br />
                Kent TN15 0RX
              </div>
              <div className="mb-3">Company No. 09013574</div>
            </div>
        
            <div className="col-md-4">
              <h2 className="h4">Get in touch</h2>
              <p>We'd love to hear from you.</p>
              <p className="">Whether it's adding our services to your business, a support question, or something else. Just give us a call and we'll be happy to help.</p>
              <p>Call Us: +44 (0)1732 465 675</p>
            </div>

          </div>

          <div className="row">
            <div><small className="d-block mb-3 text-muted">© Tariffmatch Global Ltd {(new Date().getFullYear())} | <a className="text-muted" href="/privacy-policy/">Privacy Policy</a> | <a className="text-muted" href="/terms-and-conditions/">Terms &amp; Conditions</a></small> </div>
          </div>

        </div>

      </footer>
      
    </React.Fragment>

  }

}
  
  export default Footer;