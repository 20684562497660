import React from "react";
import clientCMC from "../images/clients/cmc.png";
import clientExcalibur from "../images/clients/excalibur.png";
import clientTela from "../images/clients/tela.png";
import clientOnecom from "../images/clients/onecom.png";
import clientVodafone from "../images/clients/vodafone.png";
import clientTata from "../images/clients/tatacommunications.png";
import clientVMB from "../images/clients/vmb.png";
import clientCommTech from "../images/clients/comm-tech.png";
import clientThreeBusiness from "../images/clients/threebusiness.png";
import clientUnivonix from "../images/clients/univonix.png";
import clientVerizonBusiness from "../images/clients/verizon_business.png";
import clientARO from "../images/clients/aro.png";
import Footer from "../components/footer";

class About extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="splash">
          <div className="splashBackground team bg-dark d-flex"></div>

          <div className="cover-container position-relative flex-column text-left text-white">
            <h1 className="display-5">
              We work with companies big and small <br />
              to help them grow.
            </h1>
            <p className="display-4 text-tmo">Collaborate. Impact. Grow.</p>
          </div>
        </div>

        <div className="container mb-5">
          <div className="row featurette mb-5">
            <div className="col-lg-7">
              <h3 className="featurette-heading">Some Of Our Clients</h3>
              <p className="lead"></p>
            </div>
          </div>

          <div className="row featurette">
            <div className="col-lg-9 order-2">
              <p className="lead">
                Vodafone is a leading telecommunications company in Europe and
                Africa, led by their purpose to connect for a better future.
                They develop a range of leading products and services to connect
                customers and help build digital societies of the future.
              </p>
            </div>
            <div className="col-lg-3 col-md-12 order-1 m-auto px-5 pb-5 pb-lg-0 text-center">
              <img
                className="img-fluid"
                alt="Client Vodafone"
                src={clientVodafone}
              ></img>
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row featurette">
            <div className="col-lg-9 order-2">
              <p className="lead">
                Tata Communications enables the digital transformation of
                enterprises globally, including 300 of the Fortune 500 –
                unlocking opportunities for businesses by enabling borderless
                growth, boosting product innovation and customer experience,
                improving productivity and efficiency, building agility and
                managing risk.
              </p>
            </div>
            <div className="col-lg-3 col-md-12 order-1 m-auto px-5 pb-4 pb-lg-0 text-center">
              <img
                className="img-fluid"
                alt="Tata Communications"
                src={clientTata}
              ></img>
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row featurette">
            <div className="col-lg-9 order-2">
              <p className="lead">
              Verizon Business create the networks that connect anything to everything and everyone to anyone.
              Built for innovators, entrepreneurs and anyone with the ambition to tackle the world’s biggest challenges.
              </p>
            </div>
            <div className="col-lg-3 col-md-12 order-1 m-auto px-5 pb-4 pb-lg-0 text-center">
              <img
                className="img-fluid"
                alt="Verizon Business"
                src={clientVerizonBusiness}
              ></img>
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row featurette">
            <div className="col-lg-9 order-2">
              <p className="lead">
              Univonix was founded in 2014 by a passionate team of Unified Communications
              and VoIP veterans, Skype for Business and Cisco Unified Call Manager experts,
              seeking to provide simpler solutions to common problems in the market today.
              </p>
            </div>
            <div className="col-lg-3 col-md-12 order-1 m-auto px-5 pb-4 pb-lg-0 text-center">
              <img
                className="img-fluid"
                alt="Univonix"
                src={clientUnivonix}
              ></img>
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row featurette">
            <div className="col-lg-9 order-2">
              <p className="lead">
              Three launched in 2003 as the UK’s first 3G only network,
              laying its foundations as the network that was born to be
              different. Three UK is part of the CK Hutchison Holdings Ltd 
              group of companies which has mobile operations in 11 countries.
              </p>
            </div>
            <div className="col-lg-3 col-md-12 order-1 m-auto px-5 pb-4 pb-lg-0 text-center">
              <img
                className="img-fluid"
                alt="Three Business"
                src={clientThreeBusiness}
              ></img>
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row featurette">
            <div className="col-lg-9 order-2">
              <p className="lead">
              Tela provides communication solutions and IT business services
              to businesses, big and small. Their services span the entire
              communications mix including mobile, fixed-line, internet and
              broadband, unified communications and IT managed services.
              </p>
            </div>
            <div className="col-lg-3 col-md-12 order-1 m-auto px-5 pb-5 pb-lg-0 text-center">
              <img
                className="img-fluid"
                alt="Client Tela"
                src={clientTela}
              ></img>
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row featurette">
            <div className="col-lg-9 order-2">
              <p className="lead">
              Excalibur is a leading communications and IT company serving small to medium
              sized businesses (SMEs) and schools. It has evolved from a high street mobile
              phone shop, to a company that builds tailor made technology solutions for clients
              to improve efficiency, enable growth and increase profitability.
              </p>
            </div>
            <div className="col-lg-3 col-md-12 order-1 m-auto px-5 pb-5 pb-lg-0 text-center">
              <img
                className="img-fluid"
                alt="Client Excalibur"
                src={clientExcalibur}
              ></img>
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row featurette">
            <div className="col-lg-9 order-2">
              <p className="lead">
                For over 20 years Virgin Media Business have worked with
                organisations of all sizes across all sectors, both nationally
                and locally, helping them to meet new customer expectations and
                rise to challenging market conditions. They do this through
                their complete portfolio of Smart Solutions.
              </p>
            </div>
            <div className="col-lg-3 col-md-12 order-1 m-auto px-5 pb-5 pb-lg-0 text-center">
              <img
                className="img-fluid"
                alt="Client Virgin Media Business"
                src={clientVMB}
              ></img>
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row featurette">
            <div className="col-lg-9 order-2">
              <p className="lead">
                Onecom is the UK’s largest independent business telecoms and
                cloud communications provider, delivering fixed line, mobile,
                unified communications, connectivity and cloud solutions. They
                have achieved meteoric growth through substantial investment in
                their people and processes – a priority being to deliver
                unparalleled customer service at every level.
              </p>
            </div>
            <div className="col-lg-3 col-md-12 order-1 m-auto px-5 pb-4 pb-lg-0 text-center">
              <img
                className="img-fluid"
                alt="Client Onecom"
                src={clientOnecom}
              ></img>
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row featurette">
            <div className="col-lg-9 order-2">
              <p className="lead">
                CMC understand how to get business connected and keep it
                connected, in the office and on the move. They help find the
                right mix of landline, mobile and internet technology to keep
                business running smoothly and cost-effectively, anytime,
                anywhere.
              </p>
            </div>
            <div className="col-lg-3 col-md-12 order-1 m-auto px-5 pb-4 pb-lg-0 text-center">
              <img className="img-fluid" alt="Client CMC" src={clientCMC}></img>
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row featurette">
            <div className="col-lg-9 order-2">
              <p className="lead">
              Providing clients, their employees and customers, with sustainable competitive
              advantages by wrapping world-class cloud technologies in first-class service. 
              With expertise in technology and sustainability, they revolutionise businesses
              across industries, offering innovative solutions and expert guidance.
              </p>
            </div>
            <div className="col-lg-3 col-md-12 order-1 m-auto px-5 pb-4 pb-lg-0 text-center">
              <img className="img-fluid" alt="Client ARO" src={clientARO}></img>
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row featurette">
            <div className="col-lg-9 order-2">
              <p className="lead">
              With over 36 years of experience, Comm-Tech provide independent advice, a wealth of technical knowledge, and the latest in communications technology, to companies of all sizes across a broad spectrum of industries within the UK.
              </p>
            </div>
            <div className="col-lg-3 col-md-12 order-1 m-auto px-5 pb-3 pb-lg-0 text-center">
              <img
                className="img-fluid"
                alt="Client Comm-Tech"
                src={clientCommTech}
              ></img>
            </div>
          </div>

        </div>

        <Footer />
      </React.Fragment>
    );
  }
}

export default About;
